import { selectIsMobile } from '@redux/viewport/selectors';
import { Field, Form } from '@xo-union/tk-component-fields';
import { Body1, H4, Subtle } from '@xo-union/tk-ui-typography';
import { useAtom } from 'jotai';
import React, { FC } from 'react';
import { connect } from 'react-redux';
import { costAtom, guestCountAtom, uploadedMediaAtom } from '../../atoms';
import { ReviewFormInteraction } from '../../types';
import { isAnyPhotoErrored, isEveryPhotoCorrectSize } from '../../utils';
import { DesktopUpload } from '../desktop-upload';
import { MobileUpload } from '../mobile-upload';
import { SubmitButton } from '../submit-button';
import Styles from './styles.scss';

type StateProps = ReturnType<typeof mapStateToProps>;
interface OwnProps {
	handleClick: () => void;
	handleTrackReviewFormInteraction: (action: ReviewFormInteraction) => void;
}

export type PhotosAndBudgetStepProps = StateProps & OwnProps;

const PhotosAndBudgetStep: FC<PhotosAndBudgetStepProps> = (props) => {
	const [uploadedMedia, setUploadedMedia] = useAtom(uploadedMediaAtom);
	const {
		handleClick,
		handleTrackReviewFormInteraction,
		isMobile,
		memberId,
		vendorId,
	} = props;
	const [cost, setCost] = useAtom(costAtom);
	const [guestCount, setGuestCount] = useAtom(guestCountAtom);

	return (
		<Form onSubmit={handleClick}>
			<div className={Styles.photoSectionContainer}>
				<H4 className={Styles.sectionHeader}>Add photos to your review</H4>
				<Subtle>(optional)</Subtle>
				<Body1 className={Styles.sectionContent}>
					Your pictures will give other couples great examples of this work
				</Body1>
				{isMobile && (
					<MobileUpload
						setUploadedMedia={setUploadedMedia}
						uploadedMedia={uploadedMedia}
						handleTrackReviewFormInteraction={handleTrackReviewFormInteraction}
						memberId={memberId}
						vendorId={vendorId}
					/>
				)}
				{!isMobile && (
					<DesktopUpload
						setUploadedMedia={setUploadedMedia}
						uploadedMedia={uploadedMedia}
						handleTrackReviewFormInteraction={handleTrackReviewFormInteraction}
						memberId={memberId}
						vendorId={vendorId}
					/>
				)}
				{!isEveryPhotoCorrectSize(uploadedMedia) && (
					<p className={Styles.photoTooLarge}>
						The file size of your photo should be less than 30 MB.
					</p>
				)}
				{isAnyPhotoErrored(uploadedMedia) && (
					<p className={Styles.photoTooLarge}>
						One or more of your photos has failed to upload. Tap arrow to try
						again.
					</p>
				)}
			</div>
			<div className={Styles.sectionContainer}>
				<H4 className={Styles.sectionHeader}>Budget questions</H4>
				<Subtle>(optional)</Subtle>
				<Body1 className={Styles.sectionContent}>
					This information will help us estimate vendor costs for couples and
					will be kept anonymous.
				</Body1>
			</div>
			<div className={Styles.fieldContainer}>
				<Body1 className={Styles.title}>
					How much did you spend with this vendor?
				</Body1>
				<Field
					name="cost"
					value={cost}
					onChange={(e) => setCost(e.target.value)}
				/>
			</div>
			<div className={Styles.bottomFieldContainer}>
				<Body1 className={Styles.title}>How many guests attended?</Body1>
				<Field
					name="guestCount"
					label="Guest count"
					value={guestCount}
					onChange={(e) => setGuestCount(e.target.value)}
				/>
			</div>
			<SubmitButton>Next</SubmitButton>
		</Form>
	);
};

const mapStateToProps = (state: Redux.State) => ({
	isMobile: selectIsMobile(state),
	vendorId: state.vendor.vendorRaw?.id,
	memberId: state.membership.member?.id,
});

export default connect<StateProps, {}, OwnProps>(mapStateToProps)(
	PhotosAndBudgetStep,
);
