import { Img, Picture } from '@xo-union/tk-component-picture';
import React, { type FC, type SyntheticEvent, useCallback } from 'react';
import Styles from './styles.scss';

export interface ImageWrapProps {
	index: number;
	onError: (imageReference: SyntheticEvent<HTMLImageElement, Event>) => void;
	onLoad: () => void;
	sourceId: string;
}

const ImageWrap: FC<ImageWrapProps> = (props) => {
	const { index, onError, onLoad, sourceId } = props;

	const imageComplete = useCallback(
		(node: HTMLImageElement | null) => {
			if (node?.complete) {
				onLoad();
			}
		},
		[onLoad],
	);

	return (
		<Picture lazy id={sourceId}>
			<Img
				height={80}
				width={80}
				className={Styles.image}
				alt={`User review media ${index + 1}`}
				elementProps={{
					onError,
					onLoad,
					ref: imageComplete,
					loading: 'lazy',
				}}
			/>
		</Picture>
	);
};

export { ImageWrap };
