import Icon from '@xo-union/tk-component-icons';
import React, { ChangeEvent, Dispatch, FC, SetStateAction } from 'react';
import { connect } from 'react-redux';
import { ReviewFormInteraction, UploadedMediaState } from '../../types';
import { handleClientSideFileInput } from '../../utils';
import Styles from './styles.scss';

type StateProps = ReturnType<typeof mapStateToProps>;

interface OwnProps {
	handleTrackReviewFormInteraction: (action: ReviewFormInteraction) => void;
	setUploadedMedia: Dispatch<SetStateAction<UploadedMediaState[]>>;
}

export type PhotoUploadProps = StateProps & OwnProps;

const PhotoUpload: FC<PhotoUploadProps> = (props) => {
	const { handleTrackReviewFormInteraction, member, setUploadedMedia, vendor } =
		props;

	const handleOnClick = () => {
		handleTrackReviewFormInteraction('media upload initiated');
	};

	const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
		handleClientSideFileInput({ e, member, setUploadedMedia, vendor });
		if (e.target.files?.length !== undefined && e.target.files.length > 0) {
			handleTrackReviewFormInteraction('media upload');
		}
	};

	return (
		<div className={Styles.imageUploadWrap}>
			<input
				aria-label="File Upload"
				className={Styles.fileUploadInput}
				type="file"
				accept="image/*"
				multiple
				onChange={handleOnChange}
				onClick={handleOnClick}
			/>
			<Icon className={Styles.icon} name="add" size="md" />
		</div>
	);
};

const mapStateToProps = (state: Redux.State) => ({
	member: state.membership.member,
	vendor: state.vendor.vendorRaw,
});

export default connect<StateProps, {}, OwnProps>(mapStateToProps)(PhotoUpload);
