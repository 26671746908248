import { Provider } from 'jotai';
import React from 'react';
import ReviewModal, {
	type ReviewsModalVerTwoOwnProps,
} from './components/review-modal';

const ReviewModalVerTwo = (props: ReviewsModalVerTwoOwnProps) => {
	return (
		<Provider>
			<ReviewModal {...props} />
		</Provider>
	);
};

export default ReviewModalVerTwo;
