import { useAtomValue } from 'jotai';
import React, { type Dispatch, type FC, type SetStateAction } from 'react';
import { previouslySubmittedMediaAtom } from '../../atoms';
import type { ReviewFormInteraction, UploadedMediaState } from '../../types';
import PhotoUpload from '../photo-upload';
import { PreviouslyUploadedPhoto } from '../previously-uploaded-photo';
import { UploadedPhoto } from '../uploaded-photo';
import Styles from './styles.scss';

export interface DesktopUploadProps {
	handleTrackReviewFormInteraction: (action: ReviewFormInteraction) => void;
	memberId?: string;
	setUploadedMedia: Dispatch<SetStateAction<UploadedMediaState[]>>;
	uploadedMedia: UploadedMediaState[];
	vendorId?: string;
}

const DesktopUpload: FC<DesktopUploadProps> = (props) => {
	const {
		handleTrackReviewFormInteraction,
		memberId,
		setUploadedMedia,
		uploadedMedia,
		vendorId,
	} = props;
	const previouslySubmittedMedia = useAtomValue(previouslySubmittedMediaAtom);

	return (
		<div className={Styles.container}>
			<PhotoUpload
				setUploadedMedia={setUploadedMedia}
				handleTrackReviewFormInteraction={handleTrackReviewFormInteraction}
			/>
			{previouslySubmittedMedia.map((photo, idx) => (
				<PreviouslyUploadedPhoto
					key={photo.sourceId}
					photo={photo}
					handleTrackReviewFormInteraction={handleTrackReviewFormInteraction}
					index={idx}
				/>
			))}
			{uploadedMedia.map(
				(media) =>
					media.isCorrectSize && (
						<UploadedPhoto
							key={media.id}
							media={media}
							setUploadedMedia={setUploadedMedia}
							handleTrackReviewFormInteraction={
								handleTrackReviewFormInteraction
							}
							memberId={memberId}
							vendorId={vendorId}
						/>
					),
			)}
		</div>
	);
};

export { DesktopUpload };
