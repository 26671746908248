import { Button, type ButtonProps } from '@xo-union/tk-component-buttons';
import React from 'react';

import Styles from '../styles.scss';

const SubmitButton = ({ children, ...rest }: ButtonProps) => {
	return (
		<div className={Styles.ctaContainer}>
			<Button className={Styles.ctaButton} block="sm" color="primary" {...rest}>
				{children}
			</Button>
		</div>
	);
};

export { SubmitButton };
