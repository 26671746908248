import Icon from '@xo-union/tk-component-icons';
import { Body2 } from '@xo-union/tk-ui-typography';
import React from 'react';

import Styles from '../../styles.scss';

type RatingText = ['Poor', 'Below Average', 'Average', 'Good', 'Excellent'];

interface Props {
	onClick: (value: number) => void;
	value: number;
}

const starValues = [1, 2, 3, 4, 5];
const RATINGS: RatingText = [
	'Poor',
	'Below Average',
	'Average',
	'Good',
	'Excellent',
];

export const getRatingText = (value: number) => RATINGS[value - 1];

const StarRating = ({ onClick, value }: Props) => {
	return (
		<>
			<div className={Styles.starContainer}>
				{starValues.map((starValue) => {
					const iconName = value >= starValue ? 'star_filled' : 'star';
					return (
						<Icon
							key={starValue}
							name={iconName}
							onClick={() => onClick(starValue)}
							size="lg"
							data-testid={`${iconName}-${starValue}`}
						/>
					);
				})}
			</div>
			<Body2 className={Styles.ratingText}>{getRatingText(value)}</Body2>
		</>
	);
};

export { StarRating };
