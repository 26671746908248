import { Body1 } from '@xo-union/tk-ui-typography';
import React from 'react';
import { SubmitButton } from '../submit-button';

import Styles from './styles.scss';

interface Props {
	isLoggedIn: boolean;
	handleClick: () => void;
}

const ConfirmationSubtext = () => (
	<Body1 className={Styles.confirmationText}>
		By sharing your experience, other couples now have a better idea of what to
		expect.
	</Body1>
);
const UnauthenticatedConfirmation = () => (
	<>
		<Body1 className={Styles.confirmationText}>
			Please check your email and follow the verification link to make your
			review live.
		</Body1>
		<ConfirmationSubtext />
	</>
);
const AuthenticatedConfirmation = () => (
	<>
		<ConfirmationSubtext />
		<Body1 className={Styles.confirmationText}>
			You can see all your vendor reviews{' '}
			<a className={Styles.linkText} href="/review-wedding-vendors">
				here
			</a>
			.
		</Body1>
	</>
);

const ConfirmationStep = (props: Props) => {
	const { isLoggedIn, handleClick } = props;
	return (
		<>
			<div className={Styles.reviewContainer}>
				{isLoggedIn ? (
					<AuthenticatedConfirmation />
				) : (
					<UnauthenticatedConfirmation />
				)}
				<Body1>
					Please note: If you added photos, we&apos;ll post them after your
					review is live.
				</Body1>
			</div>
			<SubmitButton onClick={handleClick}>OK</SubmitButton>
		</>
	);
};

export { ConfirmationStep };
