import NavigableModal from '@components/NavigableModal/NavigableModal';
import { useEscape } from '@hooks/useEscape';
import { useAppSelector } from '@redux/hooks';
import { selectLoggedInStatus } from '@redux/membership/selectors';
import { compose } from '@xo-union/react-css-modules';
import { InlineAlert } from '@xo-union/tk-component-alerts';
import { IconButton } from '@xo-union/tk-component-icons';
import { ModalHeader } from '@xo-union/tk-component-modals';
import { Caption } from '@xo-union/tk-ui-typography';
import { CookieStorage } from 'cookie-storage';
import { useAtomValue, useSetAtom } from 'jotai';
import React, { FC, useCallback, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import AnalyticsConstants from '../../../../../../constants/analytics';
import { XO_SESSION_TOKEN } from '../../../../../../constants/membership';
import {
	contentAtom,
	costAtom,
	firstNameAtom,
	getReviewFormSubmissionAtom,
	guestCountAtom,
	lastNameAtom,
	previouslySubmittedMediaAtom,
	ratingAtom,
} from '../atoms';
import { useReviewModalSteps } from '../hooks/useReviewModalSteps';
import Styles from '../styles.scss';
import { ReducerState, ReviewFromRouterState } from '../types';
import { BackButton } from './back-button';
import { ConfirmationStep } from './confirmation-step';
import PhotosAndBudgetStep from './photos-and-budget-step';
import { RateVendorStep } from './rate-vendor-step';
import { SubmissionErrorStep } from './submission-error-step';
import { UserInfoStep } from './user-info-step';
import { UserInfoWithAutosignupStep } from './user-info-with-autosignup-step';

interface LocationProps {
	isFromTypeahead?: boolean;
	review?: ReviewFromRouterState;
	vendor?: Vendor.Decorated;
}

type StateProps = ReturnType<typeof mapStateToProps>;

export interface ReviewsModalVerTwoOwnProps {
	isModalOpen?: boolean;
	closeModal?: () => void;
	pageType: string;
}

type ReviewsModalVerTwoProps = StateProps & ReviewsModalVerTwoOwnProps;

const StatusHeading = ({ step, submissionError }: ReducerState) => {
	switch (step) {
		case 5:
			return (
				<InlineAlert className={Styles.inlineAlert} visible type="error">
					{submissionError === 'already-reviewed'
						? 'You reviewed this vendor'
						: 'Something went wrong'}
				</InlineAlert>
			);
		case 4:
			return (
				<InlineAlert className={Styles.inlineAlert} visible type="success">
					You just submitted a review
				</InlineAlert>
			);
		default:
			return (
				<Caption className={Styles.caption} as="p">
					Step {step} of 3
				</Caption>
			);
	}
};

const ReviewsModalVerTwo: FC<ReviewsModalVerTwoProps> = (props) => {
	const { closeModal, isLoggedIn, vendor, member, pageType } = props;
	const history = useHistory();
	const location = useLocation<LocationProps>();
	const providedVendor = location.state?.vendor ?? vendor;
	const providedReview = location.state?.review;
	const reviewFormSubmission = useAtomValue(getReviewFormSubmissionAtom);
	const memberId = useAppSelector((state) =>
		state.membership.member ? state.membership.member.id : '',
	);
	const [
		state,
		{
			handleBack,
			handleNext,
			handleSubmit,
			handleResetForm,
			handleTrackReviewFormInteraction,
			handleSubmitWithSignUpOrLogin,
			handleLogInModalClosed,
		},
	] = useReviewModalSteps(
		providedVendor,
		{ isLoggedIn, titleText: providedVendor.name },
		pageType,
		location.state?.isFromTypeahead,
	);
	const setFirstName = useSetAtom(firstNameAtom);
	const setLastName = useSetAtom(lastNameAtom);
	const setCost = useSetAtom(costAtom);
	const setGuestCount = useSetAtom(guestCountAtom);
	const setPreviouslySubmittedMedia = useSetAtom(previouslySubmittedMediaAtom);
	const setContent = useSetAtom(contentAtom);
	const setRating = useSetAtom(ratingAtom);

	const cookieStorage = new CookieStorage();
	const sessionToken = cookieStorage.getItem(XO_SESSION_TOKEN) || undefined;

	const wasLoggedInWhenOpenedTheModal = useRef(isLoggedIn);

	const useLoginFlow = !wasLoggedInWhenOpenedTheModal.current;

	useEffect(() => {
		if (pageType === 'review wedding vendors' && providedReview) {
			const providedCost = providedReview.budget.cost
				? String(providedReview.budget.cost)
				: '';
			const providedGuestCount = providedReview.budget.guestCount
				? String(providedReview.budget.guestCount)
				: '';
			setFirstName(
				providedReview.reviewer.firstName || member?.first_name || '',
			);
			setLastName(providedReview.reviewer.lastName || member?.last_name || '');
			setCost(providedCost);
			setGuestCount(providedGuestCount);
			setPreviouslySubmittedMedia(providedReview.gallery);
			setRating(providedReview.rating);
			setContent(providedReview.content);
		} else {
			setFirstName(member?.first_name || '');
			setLastName(member?.last_name || '');
		}
	}, []);

	const handleCloseModal = () => {
		closeModal?.();
		handleTrackReviewFormInteraction('dismiss');
		handleResetForm();
		if (pageType === 'review wedding vendors') {
			history.push('/review-wedding-vendors');
		}
	};

	const submitAndTrackReview = useCallback(async () => {
		const providedReviewId = providedReview?.id;

		const submitResult = await handleSubmit(
			providedVendor.id,
			reviewFormSubmission,
			sessionToken,
			isLoggedIn,
			providedReviewId,
		);

		handleTrackReviewFormInteraction(
			null,
			{
				reviewId: providedReviewId ?? submitResult?.newReviewId,
				vendorFoundOnTK: reviewFormSubmission.vendorFoundOnTK,
				vendorHired: reviewFormSubmission.vendorHired,
				mediaItemsUploaded: reviewFormSubmission.gallery.length,
				reviewBudget: reviewFormSubmission.budget?.cost,
				reviewGuestCount: reviewFormSubmission.budget?.guestCount,
				reviewText: reviewFormSubmission.content,
				memberId,
				loginFlow: useLoginFlow,
				isErrored: submitResult?.errored ?? false,
			},
			AnalyticsConstants.REVIEW_VENDOR,
		);
	}, [
		providedReview,
		providedVendor,
		reviewFormSubmission,
		sessionToken,
		isLoggedIn,
		memberId,
		useLoginFlow,
		handleSubmit,
		handleTrackReviewFormInteraction,
	]);

	useEscape(handleCloseModal);

	return (
		<NavigableModal
			hash="review-modal"
			isModalOpen={!!props.isModalOpen}
			closeCallback={handleCloseModal}
			classes={compose({ 'button-container': Styles.buttonContainer })}
			size="md"
		>
			<div className={Styles.buttonGroup}>
				<BackButton handleBack={handleBack} step={state.step} />
				<IconButton
					className={Styles.closeButton}
					name="close"
					block
					size="md"
					onClick={handleCloseModal}
					aria-label="close modal"
				/>
			</div>
			<StatusHeading {...state} />
			<ModalHeader className={Styles.modalHeader}>
				{state.titleText}
			</ModalHeader>
			<div className={Styles.divider} role="presentation" />
			<div className={Styles.stepContainer}>
				{state.step === 1 && (
					<RateVendorStep
						handleClick={handleNext}
						handleTrackReviewFormInteraction={handleTrackReviewFormInteraction}
						isMobile={props.isMobile}
					/>
				)}
				{state.step === 2 && (
					<PhotosAndBudgetStep
						handleClick={handleNext}
						handleTrackReviewFormInteraction={handleTrackReviewFormInteraction}
					/>
				)}
				{state.step === 3 &&
					(useLoginFlow ? (
						<UserInfoWithAutosignupStep
							handleClick={submitAndTrackReview}
							isLoggedIn={isLoggedIn}
							isSubmitting={state.isSubmitting}
							handleSubmitWithSignUpOrLogin={handleSubmitWithSignUpOrLogin}
							handleLogInModalClosed={handleLogInModalClosed}
						/>
					) : (
						<UserInfoStep
							handleClick={submitAndTrackReview}
							isLoggedIn={isLoggedIn}
							isSubmitting={state.isSubmitting}
						/>
					))}
				{state.step === 4 && (
					<ConfirmationStep
						handleClick={handleCloseModal}
						isLoggedIn={isLoggedIn}
					/>
				)}
				{state.step === 5 && (
					<SubmissionErrorStep
						handleClick={handleCloseModal}
						submissionError={state.submissionError || 'default'}
					/>
				)}
			</div>
		</NavigableModal>
	);
};

const mapStateToProps = (state: Redux.State) => ({
	isLoggedIn: selectLoggedInStatus(state),
	member: state.membership.member,
	vendor: state.vendor.vendor as Vendor.Decorated,
	isMobile: state.viewport.isMobile,
});

export default connect<StateProps, {}, ReviewsModalVerTwoOwnProps>(
	mapStateToProps,
)(ReviewsModalVerTwo);
