import { useEffect, useRef } from 'react';

const useRevokeObjectUrl = (serverId: string) => {
	const ref = useRef<HTMLImageElement | null>(null);

	useEffect(() => {
		const revokeObjectUrl = () => {
			if (serverId && ref.current) {
				URL.revokeObjectURL(ref.current.src);
				return ref.current;
			}
			return null;
		};
		revokeObjectUrl();
	}, [serverId]);

	return ref;
};

export { useRevokeObjectUrl };
