import fieldsLimited from './fieldsLimited';

const fragment = `
fragment FacetsRecurseLimited on Facets {
  facets {
    ...FacetFieldsLimited
    facets{
      ...FacetFieldsLimited
      facets {
        ...FacetFieldsLimited
        facets {
          ...FacetFieldsLimited
          facets {
            ...FacetFieldsLimited
            facets {
                ...FacetFieldsLimited
              facets {
                  ...FacetFieldsLimited
              }
            }
          }
        }
      }
    }
  }
}
${fieldsLimited}
`;

export default fragment;
