import { mediaApiKey, mediaApiUrl } from '@settings';
import jwt from 'jsonwebtoken';
import type { AvailableMethods, OwnerIds } from './types';

const createMediaJwt = (method: AvailableMethods) => {
	const ONE_DAY = 60 * 60 * 24 * 1000;
	const expirationDate = Date.now() + ONE_DAY;

	const payload = {
		exp: expirationDate,
		iss: mediaApiKey,
		methods: [`${method}`],
	};

	if (!process.env.MEDIA_API_SECRET) {
		throw new Error('Missing required env variable MEDIA_API_SECRET');
	}

	return jwt.sign(payload, process.env.MEDIA_API_SECRET);
};

const uploadPhoto = async (
	mediaJwt: string,
	file: File,
	ownersIds: OwnerIds,
) => {
	const headers = new Headers();
	headers.append('Authorization', `Bearer ${mediaJwt}`);

	const body = new FormData();
	body.append('file', file, file.name);

	const joinedIds = ownersIds.join(';');
	body.append('ownerIds', joinedIds);

	const requestOptions: RequestInit = {
		method: 'POST',
		headers,
		body,
	};

	return fetch(mediaApiUrl, requestOptions);
};

const deletePhoto = async (mediaJwt: string, photoId: string) => {
	const headers = new Headers();
	headers.append('Authorization', `Bearer ${mediaJwt}`);

	const requestOptions: RequestInit = {
		method: 'DELETE',
		headers,
	};

	return fetch(`${mediaApiUrl}${photoId}`, requestOptions);
};

export { createMediaJwt, deletePhoto, uploadPhoto };
