import { setAccountCreationStatus } from '@redux/rfq/actionCreators';
import {
	PasswordSessionStorageKeyName,
	generateTemporaryPassword,
	logInUser,
	signUpUser,
} from '@redux/rfq/utils';
import { Logger } from '@utils/logger/logger';
import { getMember } from '@xo-union/store-membership-redux';

export const registerAndLogUserWithRandomPassword =
	(
		emailAddress: string,
		firstName: string,
		lastName: string,
	): Redux.ThunkResult<Promise<void>> =>
	async (
		dispatch,
		_getState,
		membershipService?: MembershipService.Service,
	) => {
		const temporaryPassword = generateTemporaryPassword();

		const signUpDetails: MembershipService.SignUpParameters = {
			action: 'review-autocreate',
			application: 'marketplace',
			brand: 'theknot',
			email: emailAddress,
			first_name: firstName,
			last_name: lastName,
			password: temporaryPassword,
		};

		if (!membershipService) {
			return;
		}

		try {
			await signUpUser({ signUpDetails, membershipService });

			dispatch(setAccountCreationStatus('success'));

			dispatch({
				type: 'middleware/SET_SESSION_STORAGE',
				payload: {
					key: PasswordSessionStorageKeyName,
					value: temporaryPassword,
				},
			});

			await logInUser({
				logInDetails: {
					email: emailAddress,
					password: temporaryPassword,
				},
				membershipService,
			});

			await dispatch(getMember());
		} catch (error) {
			dispatch(setAccountCreationStatus('errored'));
			Logger.error({
				log_message: 'Auto account creation sign-up failed',
				errorToLog: error,
				newRelic: true,
			});
		}
	};
