const CONSTANTS = {
	fallbackImageUrl:
		'https://media-api.xogrp.com/images/54de075e-7a99-4211-83a2-635901ab56e4',
	imageMargin: 8,
	mobile: {
		imageWidth: 60,
	},
	desktop: {
		imageWidth: 80,
	},
};

export { CONSTANTS };
