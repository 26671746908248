import { Bone, Skeleton } from '@xo-union/tk-ui-skeleton';
import React, { type FC } from 'react';
import Styles from './styles.scss';

export interface LoadingProps {
	imageStatus: CustomHooks.UseImageStatus;
}

const Loading: FC<LoadingProps> = (props) => {
	const { imageStatus } = props;

	if (imageStatus !== 'INIT') {
		return null;
	}

	return (
		<Skeleton className={Styles.skeleton}>
			<Bone className={Styles.bone} />
		</Skeleton>
	);
};

export { Loading };
