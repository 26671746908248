import Icon from '@xo-union/tk-component-icons';
import React, { type FC } from 'react';
import Styles from '../../styles.scss';
import { isBackButtonVisible } from './utils';

export interface BackButtonProps {
	handleBack: () => void;
	step: number;
}

const BackButton: FC<BackButtonProps> = (props) => {
	const { handleBack, step } = props;

	if (!isBackButtonVisible(step)) {
		return null;
	}

	return (
		<button className={Styles.backButton} type="button" onClick={handleBack}>
			<Icon name="caret_left" size="sm" />
			<span>Back</span>
		</button>
	);
};

export { BackButton };
