import { mediaApiUrl } from '@settings';
import Icon from '@xo-union/tk-component-icons';
import Spinner from '@xo-union/tk-component-spinner';
import React, { type Dispatch, type FC, type SetStateAction } from 'react';
import { useRevokeObjectUrl } from '../../hooks/useRevokeObjectUrl';
import type { ReviewFormInteraction, UploadedMediaState } from '../../types';
import { deleteImage, handleRetry } from '../../utils';
import Styles from './styles.scss';

export interface UploadedPhotoProps {
	handleTrackReviewFormInteraction: (action: ReviewFormInteraction) => void;
	media: UploadedMediaState;
	memberId?: string;
	setUploadedMedia: Dispatch<SetStateAction<UploadedMediaState[]>>;
	vendorId?: string;
}

const UploadedPhoto: FC<UploadedPhotoProps> = (props) => {
	const {
		handleTrackReviewFormInteraction,
		media,
		memberId,
		setUploadedMedia,
		vendorId,
	} = props;
	const imageRef = useRevokeObjectUrl(media.serverReturnedMediaId);

	const handleDelete = async () => {
		if (!media.serverReturnedMediaId && media.isErrored) {
			handleTrackReviewFormInteraction('remove media');

			setUploadedMedia((prevState) => {
				const nextState = prevState.slice();
				const remainingMedia = nextState.filter((r) => r.id !== media.id);
				return remainingMedia;
			});
			return;
		}

		if (media.serverReturnedMediaId) {
			await deleteImage(media);
			handleTrackReviewFormInteraction('remove media');

			setUploadedMedia((prevState) => {
				const nextState = prevState.slice();
				const remainingMedia = nextState.filter(
					(r) => r.serverReturnedMediaId !== media.serverReturnedMediaId,
				);
				return remainingMedia;
			});
		}
	};

	return (
		<div className={Styles.container}>
			<button
				className={Styles.closeButton}
				onClick={handleDelete}
				type="button"
				aria-label="Close Button"
			>
				<Icon name="close" size="md" />
			</button>
			{!media.isErrored && media.isCorrectSize && (
				<img
					ref={imageRef}
					className={Styles.image}
					src={
						media.serverReturnedMediaId
							? `${mediaApiUrl}${media.serverReturnedMediaId}~rs_100.100`
							: media.imageData.src
					}
					alt={media.fileData.name}
				/>
			)}
			{media.isErrored && media.isCorrectSize && (
				<button
					className={Styles.buttonContainer}
					type="button"
					onClick={() =>
						handleRetry({
							file: media.fileData,
							memberId,
							setUploadedMedia,
							vendorId,
							id: media.id,
						})
					}
				>
					<img
						className={Styles.image}
						src={media.imageData.src}
						alt={media.fileData.name}
					/>
					<div
						className={`${Styles.failedOverlay} ${
							media.isUploaded ? null : Styles.failedOverlayTransparent
						}`}
					>
						{media.isUploaded && (
							<Icon className={Styles.failedIcon} name="refresh" size="lg" />
						)}
					</div>
				</button>
			)}
			{!media.isUploaded && media.isCorrectSize && (
				<div className={Styles.spinnerContainer}>
					<Spinner size="lg" />
				</div>
			)}
		</div>
	);
};

export { UploadedPhoto };
