import { Body1 } from '@xo-union/tk-ui-typography';
import React from 'react';
import type { SubmissionError } from '../../types';
import { SubmitButton } from '../submit-button';

import Styles from './styles.scss';

interface Props {
	handleClick: () => void;
	submissionError: SubmissionError;
}

const SubmissionErrorStep = ({ handleClick, submissionError }: Props) => {
	return (
		<>
			<div className={Styles.postSubmissionContainer}>
				{submissionError === 'already-reviewed' ? (
					<Body1>
						It looks like you already posted a review for this vendor. See all
						your vendor reviews <a href="/review-wedding-vendors">here</a>.
					</Body1>
				) : (
					<Body1>Please try again later.</Body1>
				)}
			</div>
			<SubmitButton onClick={handleClick}>OK</SubmitButton>
		</>
	);
};

export { SubmissionErrorStep };
