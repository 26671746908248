import useImage from '@hooks/useImage';
import React, { type ReactNode } from 'react';
import type { FCWithChildren } from 'types/react-extended';
import { CONSTANTS } from '../constants';
import { ImageWrap } from '../image_wrap';
import { Loading } from '../loading';
import Styles from './styles.scss';

export interface ThumbnailProps {
	children?: ReactNode;
	index: number;
	sourceId: string;
	onClick: () => void;
}

const Thumbnail: FCWithChildren<ThumbnailProps> = (props) => {
	const { children, index, sourceId, onClick } = props;
	const [imageStatus, onLoad, onError] = useImage(CONSTANTS.fallbackImageUrl);
	return (
		<button type="button" onClick={onClick} className={Styles.imageContainer}>
			<ImageWrap
				index={index}
				onError={onError}
				onLoad={onLoad}
				sourceId={sourceId}
			/>
			{children}
			<Loading imageStatus={imageStatus} />
		</button>
	);
};

export { Thumbnail };
